import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'

import BlogPost from '../components/posts/BlogPost'

const BlogPostTemplate = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BlogPost
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.date} - ${post.frontmatter.description}`}
            />
            <meta
              name="image"
              content={post.frontmatter.featuredimage.publicURL}
            />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={`${post.frontmatter.title}`} />
            <meta
              property="og:description"
              content={`${post.frontmatter.date} - ${post.frontmatter.description}`}
            />
            <meta property="og:url" content={post.fields.slug} />
            <meta
              property="og:image"
              content={post.frontmatter.featuredimage.publicURL}
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={`${post.frontmatter.title}`} />
            <meta
              name="twitter:description"
              content={`${post.frontmatter.date} - ${post.frontmatter.description}`}
            />
            <meta
              name="twitter:image"
              content={post.frontmatter.featuredimage.publicURL}
            />
            <meta name="twitter:creator" content="ParodyCineUni" />
            <meta
              name="article:published_time"
              content={post.frontmatter.date}
            />
            <meta name="article:author" content="Parody Cinematic Universe" />
            <meta name="article:section" content="Tech" />
            <meta name="article:tag" content={post.frontmatter.tags} />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        readTime={post.timeToRead}
        url={post.fields.slug}
      />
    </Layout>
  )
}

BlogPostTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query PetzebelPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      timeToRead
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        featuredimage {
          publicURL
        }
      }
    }
  }
`
